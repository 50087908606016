<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Seadust Layout',
  colors: {
    fields: {
      picker: true,
      palette: true,
    },
    background: {
      picker: true,
      palette: true,
    },
    foreground: {
      picker: true,
      palette: true,
    },
  },
  colorPalette: {
    '#0070b4': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#e9f0f9': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#f3f0e8': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#cbb387': {
      fields: true,
      background: true,
      foreground: true,
    },
  },
})

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/playa/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/playa/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/playa/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/playa/favicons/manifest.json', type: 'application/json' },
]

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

if (!isVoixTemplatizer)
  setupHeader('a27adfd1-a837-4704-97c4-5b910df8afc6', links)

const {
  resorts,
  brands,
  currentResort,
  footerCompanyMenu,
  footerResourcesMenu,
  footerTermsMenu,
} = await getCommonLayoutData(isVoixTemplatizer, ['brands'])

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#cbb387' })

if (currentResort?.value)
  provide('currentResort', currentResort.value.data)

if (resorts?.value)
  provide('resorts', resorts.value.data)

if (brands?.value)
  provide('brands', brands.value.data)
</script>

<template>
  <div>
    <div
      class="seadust-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />

        <SeadustNavbar
          v-if="!isVoixTemplatizer"
          :current-resort="currentResort?.data"
          :resorts="resorts?.data"
        />

        <slot />

        <SeadustFooter
          v-if="!isVoixTemplatizer"
          :current-resort="currentResort?.data"
          :footer-company-menu="footerCompanyMenu?.data"
          :footer-resources-menu="footerResourcesMenu?.data"
          :footer-terms-menu="footerTermsMenu?.data"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/sass/seadust.scss';
</style>
