<script lang="ts" setup>
import { usePageField } from '@voix/composables/usePageField'
import { computed, onMounted, ref } from 'vue'
import { usePageStore } from '@voix/store/pageStore'
import Hamburger from '../seadust/Hamburger.vue'
import Accordion from '../blocks/Accordion.vue'
import AccordionHeader from '../blocks/AccordionHeader.vue'
import AccordionItem from '../blocks/AccordionItem.vue'
import AccordionBody from '../blocks/AccordionBody.vue'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

// Config
const props = defineProps({
  currentResort: {
    type: Object,
    default: () => {},
  },
  resorts: {
    type: Array,
    required: true,
  },
})

// Composables
const route = useRoute()
const pageField = usePageField()
const pageStore = usePageStore()

// State
const menuHamburger = ref(null)
const hideBookNow = pageField.get('hideBookNow', false)
const hideNavigation = pageField.get('hideNavigation', false)
const currentPage = pageStore.currentPage
const menuIsOpen = ref(false)
const scrollY = ref(0)
const linkMouseOver = ref('')
const accentColor: { value: string } = inject('accentColor') as { value: string }
const bookingWidgetStore = useBookingWidgetStore()

// Computed
const windowIsScrolled = computed(() => scrollY.value > 0)
const showBookingWidget = computed(() => bookingWidgetStore.showBookingModal)
const menuItemsWithoutResorstsAndBrands = computed(() => {
  if (!props.currentResort?.menu)
    return []

  return props.currentResort.menu.menu_items.filter((item: any) => {
    return (
      item.name !== 'Our Resorts'
      && item.name !== 'Our Brands'
      && item.name !== 'Nuestros Resorts'
      && item.name !== 'Nuestras Marcas'
    )
  })
})
const isHomePage = computed(() => {
  return route.path === '/' || route.path === '/en' || route.path === '/es'
})
const enLink = computed(() => {
  if (currentPage?.language_code === 'en')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find(
    (localization: any) => localization.language_code === 'en',
  )
})
const esLink = computed(() => {
  if (currentPage?.language_code === 'es')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find(
    (localization: any) => localization.language_code === 'es',
  )
})
const bookNowBgColor = computed(() => {
  return accentColor.value
})
const bookNowTextColor = computed(() => {
  return '#fff'
})

// Methods
function toggleBookingModal() {
  bookingWidgetStore.toggleBookingModal('nav')
}

// Lifecycle
onMounted(() => {
  window.onscroll = () => {
    scrollY.value = window.scrollY
  }
})
</script>

<template>
  <nav
    v-if="!hideNavigation.value"
    :class="{
      'bg-transparent': isHomePage && !windowIsScrolled,
      'bg-white': !isHomePage && !windowIsScrolled,
      'scrolled bg-color-primary shadow-xl': windowIsScrolled,
    }"
    class="fixed top-0 left-0 right-0 z-50 transition-all duration-300 ease-in px-0 xl:px-12"
  >
    <!-- Navigation Content -->
    <div
      id="nav-content"
      class="h-[90px] flex items-center justify-between duration-1000 delay-200 ease-in-out"
      :class="{
        'xl:py-20': isHomePage && !windowIsScrolled,
        'xl:py-7': windowIsScrolled,
      }"
    >
      <!-- Hamburger -->
      <div class="w-1/5 relative flex justify-start top-1 left-4">
        <Hamburger ref="menuHamburger" v-model="menuIsOpen" />
      </div>

      <!-- Logo -->
      <div
        class="w-fit flex justify-center relative text-white relative right-10 xl:right-0"
      >
        <a href="/en" class="relative z-10">
          <LogosSeadust
            id="logo"
            class="w-[148px] sm:w-[174px]"
            :fill="!isHomePage && !(!isHomePage && windowIsScrolled)"
          />
        </a>
      </div>

      <!-- Language & Book Now -->
      <div class="w-1/5 flex items-center justify-end space-x-6 pr-4 xl:pr-0">
        <!-- Language -->
        <ul
          v-if="currentPage && currentPage.localizations.length"
          class="hidden md:flex items-center divide-x"
          :class="{
            'devide-white': menuIsOpen || !windowIsScrolled,
            'devide-black': !menuIsOpen && windowIsScrolled,
          }"
        >
          <li v-if="enLink" class="uppercase text-white px-2">
            <NuxtLink
              :key="enLink?.path"
              :to="enLink?.path"
              class="hover:underline"
              :class="{
                'text-color-primary': !isHomePage && !windowIsScrolled,
                'text-white': isHomePage || (!isHomePage && windowIsScrolled),
              }"
            >
              EN
            </NuxtLink>
          </li>
          <li v-if="esLink" class="uppercase text-white px-2">
            <NuxtLink
              :key="esLink?.path"
              :to="esLink?.path"
              class="hover:underline"
              :class="{
                'text-color-primary': !isHomePage && !windowIsScrolled,
                'text-white': isHomePage || (!isHomePage && windowIsScrolled),
              }"
            >
              ES
            </NuxtLink>
          </li>
        </ul>

        <!-- Book Now -->
        <button
          v-if="!hideBookNow.value"
          type="submit"
          class="btn whitespace-nowrap"
          :style="{
            backgroundColor: bookNowBgColor,
            color: bookNowTextColor,
          }"
          @click="toggleBookingModal"
        >
          {{ $t("book-now") }}
        </button>
      </div>
    </div>

    <!-- Navigation Menu -->
    <div
      class="flex flex-col justify-center items-left sm:items-center bg-color-primary w-0 h-screen absolute left-0 top-0 overflow-hidden duration-700 ease-in-out z-100"
      :class="{
        'opacity-0': !menuIsOpen,
        'opacity-100 w-screen md:w-[500px]': menuIsOpen,
      }"
    >
      <!-- Menu Logo -->
      <a
        href="/en"
        class="flex justify-center items-center h-[90px] bg-color-primary w-screen absolute top-0 pr-8 z-20"
      >
        <LogosSeadust id="logo" class="w-[174px]" :fill="false" />
      </a>

      <!-- Close Button -->
      <Icon
        name="material-symbols-light:close"
        class="w-12 h-12 md:w-16 md:h-16 text-white absolute top-4 right-4 cursor-pointer z-30"
        @click="menuHamburger.toggle()"
      />

      <!-- Menu Items -->
      <div class="overflow-y-auto py-24 z-10">
        <div
          v-for="(item, menuKey) in menuItemsWithoutResorstsAndBrands"
          :key="menuKey"
          class="flex flex-col justify-center items-start w-[65%] duration-1000 ease-in-out z-10 px-8 sm:px-0"
          :class="{
            'opacity-0': !menuIsOpen,
            'opacity-100': menuIsOpen,
          }"
        >
          <!-- Menu Item -->
          <NuxtLink
            v-if="item?.children.length === 0"
            :to="item.permalink"
            class="relative title text-left cursor-pointer font-extralight text-sm sm:text-base p-2 text-white uppercase text-nowrap"
            @mouseover="linkMouseOver = item.name"
            @mouseleave="linkMouseOver = ''"
          >
            {{ item.name }}
            <div
              class="absolute bottom-1 h-[2px] w-0 bg-white duration-300 ease-in-out"
              :class="{ 'w-6': linkMouseOver === item.name }"
            />
          </NuxtLink>

          <!-- Submenu -->
          <Accordion v-else>
            <AccordionItem :index="1" class="!items-start p-2">
              <AccordionHeader
                :is-togglable="false"
                class="relative title !text-base !no-underline text-nowrap"
                @mouseover="linkMouseOver = item.name"
                @mouseleave="linkMouseOver = ''"
              >
                <NuxtLink
                  v-if="!item.permalink.startsWith('#')"
                  :to="item.permalink"
                  :target="item.target"
                  class="relative title cursor-pointer font-extralight text-base text-white uppercase text-nowrap"
                >
                  {{ item.name }}

                  <div
                    class="absolute -bottom-2 h-[2px] w-0 bg-white duration-300 ease-in-out"
                    :class="{ 'w-6': linkMouseOver === item.name }"
                  />
                </NuxtLink>
              </AccordionHeader>
              <AccordionBody class="!py-0">
                <div
                  v-for="(childItem, itemKey) in item.children"
                  :key="itemKey"
                  class="px-8 pt-3"
                  @mouseover="linkMouseOver = childItem.name + itemKey"
                  @mouseleave="linkMouseOver = ''"
                >
                  <NuxtLink
                    v-if="!childItem.permalink.startsWith('#')"
                    :to="childItem.permalink"
                    :target="childItem.target"
                    class="relative title cursor-pointer font-extralight text-base text-white uppercase text-nowrap"
                  >
                    {{ childItem.name }}

                    <div
                      class="absolute -bottom-2 h-[2px] w-0 bg-white duration-300 ease-in-out"
                      :class="{ 'w-6': linkMouseOver === childItem.name + itemKey }"
                    />
                  </NuxtLink>
                </div>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
      </div>

      <!-- Language -->
      <ul
        v-if="currentPage && currentPage.localizations.length"
        class="flex justify-center items-center divide-x divide-white bg-color-primary absolute bottom-0 py-4 w-full z-30"
      >
        <li class="uppercase text-white px-2">
          <NuxtLink
            v-if="enLink"
            :key="enLink?.path"
            :to="enLink?.path"
            class="hover:underline text-white"
          >
            EN
          </NuxtLink>
        </li>
        <li class="uppercase text-white px-2">
          <NuxtLink
            v-if="esLink"
            :key="esLink?.path"
            :to="esLink?.path"
            class="hover:underline text-white"
          >
            ES
          </NuxtLink>
        </li>
      </ul>
    </div>

    <!-- Booking Widget -->
    <div v-if="showBookingWidget" class="px-6 py-4 w-full relative">
      <FlightBookingForm class="lg:container" />
      <button
        class="p-4 absolute z-100 top-1 lg:top-0 -right-1 m-4 lg:m-0 pointer-events-auto"
        @click="toggleBookingModal"
      >
        <Icon name="heroicons:x-mark-20-solid" class="text-gray lg:text-white w-8 h-8" />
      </button>
    </div>
  </nav>
</template>

<style scoped></style>
