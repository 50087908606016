<script setup>
import { defineExpose, onMounted, ref } from 'vue'

defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])
const route = useRoute()
const isOpen = ref(false)
const isHovering = ref(false)
const scrollY = ref(0)
const windowIsScrolled = computed(() => scrollY.value > 0)
const isHomePage = computed(() => {
  return route.path === '/' || route.path === '/en' || route.path === '/es'
})

if (typeof window !== 'undefined') {
  window.addEventListener('scroll', () => {
    scrollY.value = window.scrollY
  })
}

function toggle() {
  isOpen.value = !isOpen.value
  emit('update:modelValue', isOpen.value)
}

defineExpose({
  toggle,
})
</script>

<template>
  <div
    class="w-6 h-5 relative cursor-pointer"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
    @click="toggle"
  >
    <span
      :class="{
        'bg-color-primary': !isHomePage && !windowIsScrolled,
        'bg-white': isHomePage || !isHomePage && windowIsScrolled,
      }"
      class="h-[2px] w-full absolute top-0 right-0 duration-500 ease-in-out"
    />
    <span
      :class="{
        'bg-color-primary': !isHomePage && !windowIsScrolled,
        'bg-white': isHomePage || !isHomePage && windowIsScrolled,
        'w-[13px]': isHovering,
      }"
      class="h-[2px] w-full absolute top-[7px] right-0 duration-500 ease-in-out"
    />
    <span
      :class="{
        'bg-color-primary': !isHomePage && !windowIsScrolled,
        'bg-white': isHomePage || !isHomePage && windowIsScrolled,
      }"
      class="h-[2px] w-full absolute top-[14px] right-0 duration-500 ease-in-out"
    />
  </div>
</template>

<style scoped></style>
